<template>
	<div
		v-if="product"
		class="mb-2 grid w-full grid-cols-12 items-center border-b border-b-grey-very-light pb-2"
	>
		<!-- Image -->
		<div class="col-span-2 h-16 w-16 rounded bg-grey-very-light p-1">
			<img
				v-if="
					product.productMetadata?.productImages &&
					product.productMetadata.productImages.length
				"
				:src="
					product.productMetadata.productImages[0].urlThumbnail ||
					product.productMetadata.productImages[0].url
				"
				:alt="
					product.productMetadata.productImages[0].altText
						? product.productMetadata.productImages[0].altText
						: `Weedys ${product.name} product image`
				"
				:longdesc="product.productMetadata.productImages[0].description"
				class="mix-blend-darken"
			/>

			<ImagePlaceholder v-else />
		</div>

		<!-- Information -->
		<div class="col-span-7 ml-4 flex flex-col">
			<span class="overflow-visible font-bold leading-5">{{ product.name }}</span>
			<span class="text-xs font-light">{{ product.pricingTiers?.name }}</span>
			<span class="text-xs font-light text-zinc-600"
				>{{ item.size !== 'N/A' ? `${item.size} -` : '' }} ${{ item.price }}</span
			>
		</div>

		<!-- Quantity -->
		<InputQuantity
			:value="item.quantity"
			aria="Product quantity"
			class="col-span-3"
			width="80px"
			height="28px"
			@update:increment="
				CartStore.incrementItemQuantity(item.id, item.size, item.isGroupedProduct)
			"
			@update:decrement="CartStore.decrementItemQuantity(item.id, item.size)"
		/>

		<!-- Remove from cart button -->
		<button
			:tabindex="0"
			:aria-label="`Remove ${product.name} from cart`"
			:class="[
				'absolute -right-0.5 -top-0.5 size-3',
				'focus-visible:outline focus-visible:outline-cta',
			]"
			@click="CartStore.removeItemFromCart(item.id, item.size)"
		>
			<XMarkIcon />
		</button>
	</div>
</template>

<script setup lang="ts">
import { useProductsStore } from '../../stores/productsStore';
import { useCartStore } from '../../stores/cartStore';
import { onMounted } from 'vue';
import InputQuantity from '../ui/InputQuantity.vue';
import XMarkIcon from '@heroicons/vue/24/outline/XMarkIcon';
import ImagePlaceholder from '../icons/ImagePlaceholder.vue';
import type { Product, CartItem } from '~/types/app.types';

const ProductsStore = useProductsStore();
const CartStore = useCartStore();

const product = ref<Product>();

const props = defineProps<{ item: CartItem }>();

/**
 * Fetches a product from the products store based on the provided item properties.
 * If the item is a grouped product, it searches the 'groupedProductData' of each product in the store,
 * and returns the first product that has a nested item with the same ID as 'props.item.id'.
 * If the item is not a grouped product, it returns the first product in the store that has the same
 * 'dutchieId' as 'props.item.id'.
 *
 * @param props - The properties object which contains the cart item data.
 * @param props.item - The cart item data.
 * @param props.item.isGroupedProduct - True if the product is a grouped product.
 * @param props.item.id - The product Dutchie ID.
 * @returns The product object from the products store, or `undefined` if no match is found.
 */
function fetchProduct(): Product {
	if (props.item.isGroupedProduct) {
		return ProductsStore.products.filter((product: Product) => {
			return (
				product.groupedProductData &&
				product.groupedProductData.some((item) => item.id === props.item.id)
			);
		})[0];
	} else {
		return ProductsStore.products.filter(
			(product: Product) => product.dutchieId === props.item.id,
		)[0];
	}
}

onMounted(() => (product.value = fetchProduct()));
</script>
