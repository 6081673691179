<template>
	<div v-if="product && variant" class="flex w-full flex-col items-center gap-2 pb-3">
		<img
			v-if="
				product.productMetadata?.productImages &&
				product.productMetadata.productImages.length
			"
			:src="
				product.productMetadata.productImages[0].urlThumbnail ||
				product.productMetadata.productImages[0].url
			"
			:alt="
				product.productMetadata.productImages[0].altText
					? product.productMetadata.productImages[0].altText
					: `Weedys ${product.name} product image`
			"
			:longdesc="product.productMetadata.productImages[0].description"
			class="size-28 mix-blend-darken md:size-44 xl:size-52"
		/>

		<ImagePlaceholder v-else class="w-full" />

		<div class="flex w-full flex-col items-center gap-2 text-sm md:text-base">
			<div class="col-span-4 flex flex-col">
				<span class="font-semibold">{{ product.name }}</span>

				<span>${{ variant.price }}</span>
			</div>

			<button
				class="flex items-center gap-2 p-1 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
				:class="alreadyAdded ? 'text-cta' : 'text-text'"
				@click="
					{
						if (variant) {
							CartStore.addItemToCart(
								variant.id,
								variant.price,
								variant.priceNonDiscounted ?? null,
								variant.size,
								QUANTITY_TO_ADD,
								product.pricingTierId ?? null,
								variant.productQuantityMultiplier ?? null,
								variant.grams,
								isConcentrateProduct,
								isGroupedProduct,
								categories,
							);
							alreadyAdded = true;
							gtmCartCrossSellAddToCart();
						}
					}
				"
			>
				{{ buttonText }}
				<PlusCircleIcon
					v-if="!alreadyAdded"
					class="w-6 cursor-pointer text-text transition duration-200 hover:scale-105"
				/>

				<CheckCircleIcon
					v-else
					class="w-6 cursor-pointer text-cta transition duration-200 hover:scale-105"
				/>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import useProductOptions from '../../composables/productOptions';
import { useCartStore } from '../../stores/cartStore';
import PlusCircleIcon from '@heroicons/vue/20/solid/PlusCircleIcon';
import CheckCircleIcon from '@heroicons/vue/20/solid/CheckCircleIcon';
import { computed } from 'vue';
import handleFrontendError from '../../utils/handleFrontendError';
import { useGtm } from '@gtm-support/vue-gtm';
import ImagePlaceholder from '../icons/ImagePlaceholder.vue';
import type { Product } from '~/types/app.types';

const CartStore = useCartStore();
const QUANTITY_TO_ADD = 1;

const props = defineProps<{ product: Product }>();

const { categories, isConcentrateProduct, isGroupedProduct, variant } = useProductOptions(
	props.product,
);

const alreadyAdded = ref(false);
const buttonText = computed(() => (alreadyAdded.value ? 'Added to cart' : 'Add to cart'));

function gtmCartCrossSellAddToCart() {
	try {
		useGtm()?.trackEvent({
			event: 'cart-cross-sell-product-added-to-cart',
			category: 'Cross Sells',
			action: 'Add to Cart',
			value: variant.value?.price,
			productName: props.product.name,
			productSize: variant.value?.size,
			productQuantity: 1,
			noninteraction: false,
		});
	} catch (error) {
		handleFrontendError(error, false);
	}
}
</script>
