<template>
	<div>
		<div class="flex w-auto cursor-pointer items-center">
			<span
				class="absolute right-[-3px] top-[22px] z-10 -mt-1 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary text-[0.675rem] font-medium text-black md:mt-0"
				>{{ productsLoaded ? CartStore.itemsCount : 0 }}</span
			>
			<p v-if="CartStore.total > 0" class="-mb-[3px] -mt-0.5 font-medium text-white md:mt-0">
				${{ CartStore.total.toFixed(2) }}
			</p>

			<img
				v-if="ThemeStore.cartIcon"
				:src="ThemeStore.cartIcon.url"
				alt="Theme cart icon"
				class="h-10 w-10"
			/>
			<IconsShoppingBag v-else class="-mt-1 h-9 w-9 fill-white md:mt-0 md:h-10 md:w-10" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cartStore';
import { useThemeStore } from '~/stores/themeStore';
import { onMounted } from 'vue';
import waitForProducts from '../../utils/waitForProducts';

const CartStore = useCartStore();
const ThemeStore = useThemeStore();

const productsLoaded = ref(false);

onMounted(async () => {
	await waitForProducts();
	productsLoaded.value = true;
});
</script>
